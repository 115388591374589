import { ReactNode, DetailedHTMLProps, HTMLAttributes, useMemo } from "react";
import styled from "styled-components";
import { Merge } from "type-fest";
import { v4 as uuid } from "uuid";

import { Error as ErrorIcon } from "@sol/icons";

import { Text } from "../../../uikit";

const Label = styled(Text).attrs({
    as: "label",
})`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
`;

const Star = styled.sup`
    color: ${({ theme }) => theme.palette.red.base};
`;

const Helper = styled(Text)`
    color: ${({ theme }) => theme.palette.grey.base};
`;

const Error = styled(Text)`
    --text-color: ${({ theme }) => theme.palette.red.base};
    --icon-color: ${({ theme }) => theme.palette.red.base};
    --icon-bg-color: ${({ theme }) => theme.palette.red.lightest};

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;

    > ${ErrorIcon} {
        display: inline-block;
        vertical-align: middle;
        margin-right: ${({ theme }) => theme.spacing[3]};
    }
`;

type Props = Merge<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    {
        small?: boolean;
        label?: ReactNode;
        htmlFor?: string;
        helper?: ReactNode;
        error?: { message?: ReactNode | string };
        required?: boolean;
        children: (options: { errorId?: string }) => ReactNode;
    }
>;

const InputDecorator = ({ small, label, htmlFor, helper, error, required, children, ...props }: Props) => {
    const errorId = useMemo(() => uuid(), []);

    return (
        <div {...props}>
            {label && (
                <Label as="label" variant={small ? "p" : "label"} htmlFor={htmlFor}>
                    {label} {required && <Star>*</Star>}
                    {helper && (
                        <Helper as="span" variant={small ? "subheading" : "p"}>
                            {" - "}
                            {helper}
                        </Helper>
                    )}
                </Label>
            )}
            {children({ errorId: error?.message ? errorId : undefined })}
            {error?.message && (
                <Error id={errorId}>
                    <ErrorIcon size={16} circle />
                    <span>{error.message}</span>
                </Error>
            )}
        </div>
    );
};

export default styled(InputDecorator)`
    position: relative;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing[2]};

    ${Error} {
        position: absolute;
        bottom: 0;
        transform: translateY(calc(100% + ${({ theme }) => theme.spacing[2]}));
    }
`;
