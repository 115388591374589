import { Key } from "react";
import { useGridListSelectionCheckbox } from "react-aria";
import { ListState } from "react-stately";
import styled from "styled-components";

import Checkbox from "../inputs/Checkbox";

type Props<T> = {
    className?: string;
    state: ListState<T>;
    itemKey: Key;
};

function ListCheckbox<T>({ className, state, itemKey }: Props<T>) {
    const { checkboxProps } = useGridListSelectionCheckbox({ key: itemKey }, state);

    return <Checkbox {...checkboxProps} className={className} />;
}

export default styled(ListCheckbox)``;
