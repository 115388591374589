import { css } from "styled-components";

export const focusRingCSS = css`
    --focus-ring-shadow: 0px 0px 0px calc(${({ theme }) => theme.spacing[2]} * var(--focus-ring-active, 0))
        var(--focus-ring-color, ${({ theme }) => theme.palette.purple.light});
`;

export const focusRing = ({
    inset,
    selector,
}: { inset?: boolean; selector?: "focus" | "focus-visible" | "focus-within" } = {}) => {
    return [
        "ring-2",
        "ring-offset-2",
        ...(inset
            ? ["ring-inset", "ring-white-base", "ring-offset-blue-focus"]
            : ["ring-blue-focus", "ring-offset-white-base"]),
    ].map(className => `${selector ?? "focus-visible"}:${className}`);
};

export const focusRingWithinClasses = [
    "focus-within:ring-2",
    "focus-within:ring-offset-2",
    "focus-within:ring-blue-focus",
    "focus-within:ring-offset-white-base",
];

export const focusRingWithinInsetClasses = [
    "focus-within:ring-2",
    "focus-within:ring-offset-2",
    "focus-within:ring-inset",
    "focus-within:ring-offset-blue-focus",
    "focus-within:ring-white-base",
];

export const focusRingVisibleClasses = [
    "focus-visible:ring-2",
    "focus-visible:ring-offset-2",
    "focus-visible:ring-blue-focus",
    "focus-visible:ring-offset-white",
];

export const focusRingInsetClasses = [
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-inset",
    "focus:ring-offset-blue-focus",
    "focus:ring-white-base",
];

export const focusRingVisibleInsetClasses = [
    "focus-visible:ring-2",
    "focus-visible:ring-offset-2",
    "focus-visible:ring-inset",
    "focus-visible:ring-offset-blue-focus",
    "focus-visible:ring-white-base",
];
