import React, { ComponentProps } from "react";
import styled from "styled-components";

import { Caution, Check, SvgIcon } from "@sol/icons";

import { Text } from "../../../uikit";

type ProgressBarItemVariants = "default" | "success" | "danger" | "primary";

export type ProgressBarItem = {
    id: string;
    title?: string;
    variant: ProgressBarItemVariants;
};

const Item = styled.li`
    flex: 1;

    border-radius: 40px;
    background-color: ${({ theme }) => theme.palette.white.base};
    padding: ${({ theme }) => theme.spacing[2]};
    z-index: ${({ theme }) => theme.zIndex.element};

    display: flex;
    justify-content: center;

    &[data-variant="default"] {
        --text-color: ${({ theme }) => theme.palette.grey.base};
    }

    &[data-variant="success"] + &[data-variant="success"],
    &[data-variant="default"] + &[data-variant="default"],
    &[data-variant="primary"] + &[data-variant="primary"],
    &[data-variant="danger"] + &[data-variant="danger"] {
        position: relative;

        :before {
            content: " ";

            width: calc(${({ theme }) => theme.shape.borderRadius.medium} * 2 + ${({ theme }) => theme.spacing[5]});
            position: absolute;
            height: calc(100% + 4px);
            top: -2px;
            left: calc(
                -1 * (${({ theme }) => theme.shape.borderRadius.medium} + ${({ theme }) => theme.spacing[3]} + 6px)
            );
            z-index: ${({ theme }) => theme.zIndex.overlay};
        }
    }

    &[data-variant="danger"] {
        background-color: ${({ theme }) => theme.palette.red.lightest};
        --text-color: ${({ theme }) => theme.palette.red.base};
        --icon-color: ${({ theme }) => theme.palette.red.base};
        border: 2px solid ${({ theme }) => theme.palette.red.base};

        & + &:before {
            background: ${({ theme }) => theme.palette.red.lightest};
            border-top: 2px solid ${({ theme }) => theme.palette.red.base};
            border-bottom: 2px solid ${({ theme }) => theme.palette.red.base};
        }
    }

    &[data-variant="success"] {
        background-color: ${({ theme }) => theme.palette.green.lightest};
        --text-color: ${({ theme }) => theme.palette.green.base};
        --icon-color: ${({ theme }) => theme.palette.green.base};
        border: 2px solid ${({ theme }) => theme.palette.green.base};

        & + &:before {
            background: ${({ theme }) => theme.palette.green.lightest};
            border-top: 2px solid ${({ theme }) => theme.palette.green.base};
            border-bottom: 2px solid ${({ theme }) => theme.palette.green.base};
        }
    }

    &[data-variant="primary"] {
        background-color: ${({ theme }) => theme.palette.purple.lighter2};
        --text-color: ${({ theme }) => theme.palette.purple.base};
        --icon-color: ${({ theme }) => theme.palette.purple.base};
        border: 2px solid ${({ theme }) => theme.palette.purple.base};

        & + &:before {
            background: ${({ theme }) => theme.palette.purple.lighter2};
            border-top: 2px solid ${({ theme }) => theme.palette.purple.base};
            border-bottom: 2px solid ${({ theme }) => theme.palette.purple.base};
        }
    }

    &[data-variant="default"] {
        background-color: ${({ theme }) => theme.palette.white.base};
        --icon-color: ${({ theme }) => theme.palette.grey.base};
        --text-color: ${({ theme }) => theme.palette.grey.base};
        border: 2px solid ${({ theme }) => theme.palette.grey.lightest};
        border-radius: 40px;
    }

    ${SvgIcon} {
        display: inline;
        width: 16px;
        height: 16px;
        margin-right: ${({ theme }) => theme.spacing[3]};
    }
`;

const getVariantIcon = (variant: ProgressBarItem["variant"]) => {
    switch (variant) {
        case "success":
            return <Check data-cy="check-icon" />;

        case "danger":
            return <Caution data-cy="caution-icon" />;

        default:
            return;
    }
};

type Props = {
    levels: ProgressBarItem[];
    className?: string;
} & ComponentProps<"ol">;

const ProgressBar = ({ levels, className }: Props) => {
    return (
        <ol className={className} data-cy="progressbar">
            {levels.map(level => (
                <Item data-cy="progressbar-item" key={level.id} data-variant={level.variant}>
                    {level.title && (
                        <>
                            {getVariantIcon(level.variant)}
                            <Text data-cy="progressbar-item-title" textAlign="center" variant="subheading">
                                {level.title}
                            </Text>
                        </>
                    )}
                </Item>
            ))}
        </ol>
    );
};

export default styled(ProgressBar)`
    display: flex;
    position: relative;

    padding: 0;
    margin: 0;
    gap: ${({ theme }) => theme.spacing[3]};
    min-height: 24px;

    list-style-type: none;

    ${Item}[data-variant="success"]:first-of-type,
    ${Item}[data-variant="primary"]:first-of-type,
    ${Item}[data-variant="danger"]:first-of-type {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }
`;
