import { css } from "styled-components";

export const skeletonBgCSS = css`
    background-color: var(--skeleton-color-base, ${({ theme }) => theme.palette.grey.lightest});
    background-image: ${({ theme }) => css`linear-gradient(
        80deg,
        var(--skeleton-color-base, ${theme.palette.grey.lightest}),
        var(--skeleton-color-highlight, ${theme.palette.purple.lightest}),
        var(--skeleton-color-base, ${theme.palette.grey.lightest})
    )`};
    background-size: 200px 100%;
    background-repeat: no-repeat;

    @keyframes loading {
        0% {
            background-position: -200px 0;
        }
        100% {
            background-position: calc(200px + 100%) 0;
        }
    }

    animation: loading 1.2s ease-in-out infinite;
`;
