import { motion, AnimatePresence } from "framer-motion";
import React, { ComponentPropsWithoutRef } from "react";
import styled, { useTheme } from "styled-components";

type Props = {
    isExpanded?: boolean;
} & ComponentPropsWithoutRef<typeof motion.div>;

const variants = {
    expanded: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 },
};

export const ExpandablePanel = styled(({ id, isExpanded, className, children, ...props }: Props) => {
    const theme = useTheme();

    return (
        <AnimatePresence initial={true}>
            {isExpanded ? (
                <motion.div
                    layoutId={id}
                    className={className}
                    key="content"
                    initial="collapsed"
                    animate="expanded"
                    exit="collapsed"
                    variants={variants}
                    transition={theme.transitions.spring.default}
                    {...props}
                >
                    {children}
                </motion.div>
            ) : null}
        </AnimatePresence>
    );
})``;
