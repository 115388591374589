import styled from "styled-components";

type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type OffsetSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

type Props = {
    col?: ColumnSize;
    start?: OffsetSize;
};

const GridColumn = styled.div<Props>`
    min-width: 0;
    grid-column: ${({ start = 0, col }) => (start ? `${start + 1} / span ${col}` : `span ${col}`)};
`;

export default GridColumn;
