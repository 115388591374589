import { css } from "styled-components";

import { Theme } from "../../../uikit/theme";

export type InputVariant = "error" | "success" | "default" | "info";

export const getInputVariantStyle = ({ theme, variant }: { theme: Theme; variant?: InputVariant }) => {
    const notDefaultStyle = (borderColor: string) => css`
        border: 1px solid ${borderColor};
        box-shadow: 0 0 0 0 transparent, inset 0 0 0 1px ${borderColor};

        transition: box-shadow 0.2s;
        will-change: box-shadow;

        &:focus {
            outline-offset: 1px;
            outline-width: 4px;
        }
    `;

    switch (variant) {
        case "info":
            return notDefaultStyle(theme.palette.purple.base);
        case "success":
            return notDefaultStyle(theme.palette.green.base);
        case "error":
            return notDefaultStyle(theme.palette.red.base);
        case "default":
        default:
            return css`
                border: 1px solid ${theme.palette.grey.lighter};
                box-shadow: 0 0 0 0 transparent;

                transition: box-shadow 0.2s;
                will-change: box-shadow;

                &:focus {
                    outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
                    outline-offset: 2px;
                }
            `;
    }
};
