import styled, { css } from "styled-components";

import { Theme } from "../../../uikit/theme";

export type TagVariants = "default" | "primary" | "success" | "danger" | "warning" | "blue" | "orange" | "grey";

type Props = {
    variant?: TagVariants;
};

const getVariant = (theme: Theme, variant: Props["variant"]) => {
    switch (variant) {
        case "primary":
            return {
                color: theme.palette.purple.base,
                backgroundColor: theme.palette.purple.lightest,
                border: `1px solid ${theme.palette.purple.light}`,
            };
        case "success":
            return {
                color: theme.palette.green.base,
                backgroundColor: theme.palette.green.lightest,
                border: `1px solid ${theme.palette.green.light}`,
            };
        case "danger":
            return {
                color: theme.palette.red.base,
                backgroundColor: theme.palette.red.lightest,
                border: `1px solid ${theme.palette.red.light}`,
            };
        case "blue":
            return {
                color: theme.palette.blue.base,
                backgroundColor: theme.palette.blue.lightest,
                border: `1px solid ${theme.palette.blue.base}`,
            };
        case "grey":
            return {
                color: theme.palette.grey.base,
                backgroundColor: theme.palette.white.base,
                border: `1px solid ${theme.palette.grey.light}`,
            };
        case "orange":
            return {
                color: theme.palette.orange.base,
                backgroundColor: `linear-gradient(0deg, rgba(244, 108, 16, 0.06), rgba(244, 108, 16, 0.06)), #FFFFFF`,
                border: `1px solid ${theme.palette.orange.base}`,
            };
        default:
            return {
                color: theme.palette.grey.base,
                backgroundColor: theme.palette.white.base,
                border: `1px solid ${theme.palette.grey.lightest}`,
            };
    }
};

export const Tag = styled.span<Props>`
    ${({ theme }) => theme.typography.subheading}
    display: inline-flex;
    margin: 0;
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};

    justify-content: center;
    align-items: center;

    ${({ theme, variant }) => {
        const { color, backgroundColor, border } = getVariant(theme, variant);

        return css`
            --icon-color: ${color};
            color: ${color};
            background-color: ${backgroundColor};
            border: ${border};
        `;
    }}
`;

export default Tag;
