import isNumber from "lodash/isNumber";
import styled from "styled-components";

type TableCellProps = { flex?: number | boolean };

export const TableCell = styled.div<TableCellProps>`
    ${({ flex }) => flex && `flex: ${isNumber(flex) ? flex : 1};`}
`;

export const TableRow = styled.li`
    // Standardized height
    height: 80px;
    width: 100%;

    padding: 0 ${({ theme }) => theme.spacing[6]};
    background-color: ${({ theme }) => theme.palette.white.base};

    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    display: flex;
    align-items: center;

    ${TableCell} + ${TableCell} {
        margin-left: ${({ theme }) => theme.spacing[8]};
    }
`;

export const Table = styled.ul`
    list-style: none;

    padding: 0;
    margin: 0;

    ${TableRow} + ${TableRow} {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

export default Table;
