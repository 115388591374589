import React, { ComponentProps } from "react";
import styled from "styled-components";

import { ProgressBarItem, ProgressBar } from ".";
import { Text } from "../../../uikit";

const Title = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.palette.grey.base};
`;

type Props = {
    title: string;
    levels: ProgressBarItem[];
    className?: string;
} & ComponentProps<"div">;

const SkillProgressBar = ({ title, levels, className, ...divProps }: Props) => {
    return (
        <div {...divProps} className={className}>
            <Title data-cy="skill-progress-bar-title" lines={2} variant="subheading">
                {title}
            </Title>
            <ProgressBar levels={levels} />
        </div>
    );
};

export default styled(SkillProgressBar)`
    display: flex;
    flex-direction: column;

    width: 100%;
`;
