import { cva, type VariantProps } from "class-variance-authority";

export type BasicButtonsVariantsProps = VariantProps<typeof basicButtonsVariants>;

const baseClasses = [
    "flex justify-center",
    "items-center",
    "w-fit",
    "border-2",
    "rounded-medium",
    "font-main",
    "transition-all",
    "ease-in-out",
    "duration-200",
    "focus:outline-none",
    "disabled:opacity-60",
    "disabled:cursor-not-allowed",
];
export const basicButtonsVariants = cva(baseClasses, {
    variants: {
        variant: {
            primary: ["bg-purple-base", "border-purple-base", "text-white-base"],
            secondary: ["border-purple-base", "text-purple-base", "active:bg-purple-lighter"],
            success: ["border-green-base", "text-green-base", "active:bg-green-lightest"],
            danger: ["border-red-base", "text-red-base", "active:bg-red-lightest"],
        },
        size: {
            standard: [
                "py-3",
                "px-6",
                "gap-3",
                "font-bold",
                "text-base",
                "enabled:hover:translate-x-[calc(-1*3px)]",
                "enabled:hover:translate-y-[calc(-1*3px)]",
            ],
            small: ["px-2", "py-1", "gap-1.5", "text-xs", "font-normal"],
        },
    },
    compoundVariants: [
        {
            variant: ["secondary", "success", "danger"],
            size: ["standard", "small"],
            className: "bg-white-base",
        },
        {
            variant: "primary",
            size: "standard",
            className: "enabled:hover:shadow-primary",
        },
        {
            variant: "secondary",
            size: "standard",
            className: "enabled:hover:shadow-secondary",
        },
        {
            variant: "success",
            size: "standard",
            className: "enabled:hover:shadow-success",
        },
        {
            variant: "danger",
            size: "standard",
            className: "enabled:hover:shadow-danger",
        },
        {
            variant: "primary",
            size: "small",
            className: "enabled:hover:shadow-small-primary",
        },
        {
            variant: "secondary",
            size: "small",
            className: "enabled:hover:shadow-small-secondary",
        },
        {
            variant: "success",
            size: "small",
            className: "enabled:hover:shadow-small-success",
        },
        {
            variant: "danger",
            size: "small",
            className: "enabled:hover:shadow-small-danger",
        },
    ],
    defaultVariants: { variant: "primary", size: "standard" },
});

export const loaderVariants = {
    standard: {
        primary: { fillColor: "white-base", strokeColor: "white-base" },
        secondary: { fillColor: "purple-base", strokeColor: "purple-base" },
        success: { fillColor: "green-base", strokeColor: "green-base" },
        danger: { fillColor: "red-base", strokeColor: "red-base" },
    },
    small: {
        primary: { fillColor: "white-base", strokeColor: "white-base" },
        secondary: { fillColor: "purple-base", strokeColor: "purple-base" },
        success: { fillColor: "green-base", strokeColor: "green-base" },
        danger: { fillColor: "red-base", strokeColor: "red-base" },
    },
};
