import { useObjectRef } from "@react-aria/utils";
import { forwardRef, ForwardedRef, Key, ReactNode, useMemo } from "react";
import { AriaGridListOptions, useGridList } from "react-aria";
import { useListState, ListProps, ListState } from "react-stately";
import styled from "styled-components";
import { RequireAllOrNone } from "type-fest";

import GridListItem from "./GridListItem";

type ExpansionStateProps<T extends object = object> = {
    expandableKeys: "all" | Array<Key>;
    expandedKeys: Array<Key>;
    onExpand: (keys: Array<Key>) => void;
    expansionMode?: "multiple" | "accordion";
    renderExpandable: (item: T | null) => ReactNode;
};

type ExpansionProps<T extends object = object> = RequireAllOrNone<ExpansionStateProps<T>, keyof ExpansionStateProps>;

type Props<T extends object> = { className?: string } & ExpansionProps<T> & ListProps<T> & AriaGridListOptions<T>;

function useExpansionState<T extends object = object>(props: ExpansionProps<T>, state: ListState<T>) {
    const { expandableKeys, expandedKeys = [], onExpand, renderExpandable, expansionMode } = props;

    const collection = useMemo(() => {
        const collection = new Map<
            Key,
            {
                isExpandable: boolean;
                isExpanded: boolean;
                onExpandClick: () => void;
                rendered: ReactNode;
            }
        >();

        for (let idx = 0; idx < state.collection.size; idx++) {
            const item = state.collection.at(idx);

            if (item) {
                const isExpandable = expandableKeys === "all" ? true : expandableKeys?.includes(item.key) ?? false;
                const isExpanded = expandedKeys?.includes(item.key) ?? false;
                const handleExpandClick = () => {
                    if (onExpand) {
                        if (expansionMode === "accordion") {
                            onExpand(isExpanded ? [] : [item.key]);
                        } else {
                            onExpand(
                                isExpanded
                                    ? (expandedKeys ?? []).filter(key => key !== item.key)
                                    : [...(expandedKeys ?? []), item.key],
                            );
                        }
                    }
                };

                collection.set(item.key, {
                    isExpandable,
                    isExpanded,
                    onExpandClick: handleExpandClick,
                    rendered: renderExpandable?.(item.value) ?? null,
                });
            }
        }

        return collection;
    }, [state.collection]);

    return {
        collection,
    };
}

function GridList<T extends object = object>(
    { className, ...props }: Props<T>,
    forwardedRef: ForwardedRef<HTMLUListElement>,
) {
    const state = useListState<T>(props);
    const ref = useObjectRef(forwardedRef);
    const { gridProps } = useGridList<T>(props, state, ref);
    const { collection } = useExpansionState<T>(props, state);

    return (
        <ul {...gridProps} ref={ref} className={className}>
            {[...state.collection].map(item => (
                <GridListItem<T> key={item.key} node={item} state={state} expansion={collection.get(item.key)} />
            ))}
        </ul>
    );
}

export default styled(forwardRef(GridList))`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
    padding: 0;
    margin: 0;
    list-style: none;
` as typeof GridList;
