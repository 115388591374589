import styled, { css } from "styled-components";

import { skeletonBgCSS } from "../utils/skeleton";
import { InputVariant, getInputVariantStyle } from "./InputStatus";

type Props = {
    isLoading?: boolean;
    variant?: InputVariant;
};

const TextInput = styled.input<Props>`
    ${({ theme, variant, isLoading }) => {
        return css`
            width: 100%;
            height: 48px;

            background-color: ${theme.palette.white.base};

            padding: ${({ theme }) => theme.spacing[4]};
            border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

            ${theme.typography.p}

            &::placeholder {
                color: ${theme.palette.grey.base};
            }

            &:disabled {
                opacity: 0.5;
            }

            ${getInputVariantStyle({ theme, variant })}

            --skeleton-color-base: ${({ theme }) => theme.palette.white.base};
            --skeleton-color-highlight: ${({ theme }) => theme.palette.grey.lightest};
            ${isLoading ? skeletonBgCSS : undefined}
        `;
    }}
`;

export default TextInput;
