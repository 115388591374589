import styled, { css } from "styled-components";

type Props = {
    block?: boolean;
};

export const Container = styled.div<Props>`
    padding: 0 ${({ theme }) => theme.spacing[4]};
    margin: 0 auto;

    width: 100%;
    max-width: 1280px;

    ${({ theme, block }) =>
        block &&
        css`
            padding: ${({ theme }) => theme.spacing[5]};
            border-radius: ${theme.shape.borderRadius.medium};
            background: ${theme.palette.white.base};
        `}
`;

export default Container;
