import { useObjectRef } from "@react-aria/utils";
import { ForwardedRef, forwardRef, ReactNode, Suspense, useRef } from "react";
import {
    AriaButtonProps,
    AriaGridListItemOptions,
    mergeProps,
    useButton,
    useFocusRing,
    useGridListItem,
    useId,
} from "react-aria";
import { ListState, Node } from "react-stately";
import styled, { css } from "styled-components";

import { Chevron, Loading } from "@sol/icons";
import { IconButton } from "@sol/uikit/buttons";
import { ExpandablePanel } from "@sol/uikit/ExpandablePanel";
import { focusRingCSS } from "@sol/uikit/utils/focus";

import ListCheckbox from "../ListCheckbox";

export const Button = styled(function Button({
    className,
    ...props
}: { className?: string } & AriaButtonProps<"button">) {
    const ref = useRef<HTMLButtonElement | null>(null);
    const { buttonProps } = useButton(props, ref);
    const { children } = props;

    return (
        <button className={className} {...buttonProps} ref={ref}>
            {children}
        </button>
    );
})``;

const RowContent = styled.div<{ isExpanded: boolean }>`
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: row;

    border-top-left-radius: calc(${({ theme }) => theme.shape.borderRadius.medium} - 2px);
    border-bottom-left-radius: calc(${({ theme }) => theme.shape.borderRadius.medium} - 2px);

    transition: border-bottom-left-radius 0.1s;

    ${({ isExpanded }) =>
        isExpanded &&
        css`
            border-bottom-left-radius: 0;
        `}
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;

    ${ListCheckbox} {
        position: absolute;
        left: calc(-1 * ${({ theme }) => theme.spacing[5]});
        transform: translateX(-100%);
        align-self: center;
    }

    > ${Button} {
        margin: ${({ theme }) => theme.spacing[6]};
        align-self: center;
    }
`;

const GridCell = styled.div<{
    isSelected?: boolean;
    hasCheckbox: boolean;
    isExpandable: boolean;
    isFocused: boolean;
    hasAction: boolean;
    allowsSelection: boolean;
}>`
    display: flex;
    flex-direction: column;

    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    box-shadow: var(--focus-ring-shadow);
    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};

    transition: box-shadow 0.2s;

    ${({ theme, isSelected }) =>
        isSelected &&
        css`
            border-color: ${theme.palette.purple.base};
        `}

    ${({ theme, hasCheckbox }) =>
        hasCheckbox &&
        css`
            margin-left: calc(${theme.spacing[5]} + 18px);
        `}

    ${({ isFocused }) =>
        isFocused &&
        css`
            --focus-ring-active: 1;
        `}
    ${({ hasAction, allowsSelection }) =>
        (hasAction || allowsSelection) &&
        css`
            cursor: pointer;
        `}

    ${focusRingCSS};

    ${ExpandablePanel} {
        border-bottom-left-radius: calc(${({ theme }) => theme.shape.borderRadius.medium} - 2px);
        border-bottom-right-radius: calc(${({ theme }) => theme.shape.borderRadius.medium} - 2px);
        overflow: hidden;
    }
`;

type Props<T> = {
    className?: string;
    state: ListState<T>;
    node: Node<T>;
    expansion?: {
        isExpandable: boolean;
        isExpanded: boolean;
        onExpandClick: () => void;
        rendered: ReactNode;
    };
} & AriaGridListItemOptions;

function GridListItem<T>(
    { state, className, expansion: exp, ...props }: Props<T>,
    forwardedRef: ForwardedRef<HTMLLIElement>,
) {
    const {
        rendered: expansionChildren,
        onExpandClick,
        ...expansion
    } = exp ?? { isExpandable: false, isExpanded: false };

    const ref = useObjectRef(forwardedRef);
    const expandId = useId();

    const { node } = props;
    const { rowProps, gridCellProps, isSelected, hasAction, allowsSelection } = useGridListItem(props, state, ref);

    const { focusProps, isFocusVisible } = useFocusRing();
    const showCheckbox =
        state.selectionManager.selectionMode !== "none" && state.selectionManager.selectionBehavior === "toggle";

    const { isExpandable = false, isExpanded = false } = expansion;

    const renderGridCell = (isLoading: boolean) => {
        return (
            <GridCell
                role="gridcell"
                allowsSelection={allowsSelection}
                hasAction={hasAction}
                isFocused={isFocusVisible}
                isExpandable={isExpandable}
                hasCheckbox={showCheckbox}
                isSelected={isSelected}
                {...gridCellProps}
            >
                <Header>
                    {showCheckbox && <ListCheckbox itemKey={node.key} state={state} />}
                    <RowContent isExpanded={isExpanded && !isLoading}>{node.rendered}</RowContent>

                    {isExpandable ? (
                        <IconButton
                            key="open"
                            as={Button}
                            aria-controls={expandId}
                            onPress={!isLoading ? onExpandClick : undefined}
                            isDisabled={isLoading}
                        >
                            {isLoading ? <Loading /> : <Chevron direction={isExpanded ? "up" : "down"} />}
                        </IconButton>
                    ) : null}
                </Header>

                {isExpandable ? (
                    <ExpandablePanel id={expandId} isExpanded={isExpanded && !isLoading}>
                        {expansionChildren}
                    </ExpandablePanel>
                ) : null}
            </GridCell>
        );
    };

    return (
        <li role="row" {...mergeProps(rowProps, focusProps)} ref={ref} className={className}>
            <Suspense fallback={renderGridCell(true)}>{renderGridCell(false)}</Suspense>
        </li>
    );
}

export default styled(forwardRef(GridListItem))`
    outline: none;
` as typeof GridListItem;
