import styled, { css } from "styled-components";

const skeletonStyle = css`
    --_color: var(--skeleton-color, ${({ theme }) => theme.palette.grey.light});

    background: var(--_color);

    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
        0%,
        100% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
    }
`;

export const TextSkeleton = styled.span`
    height: 1em;
    width: 100%;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    --skeleton-color: var(--text-color);

    display: inline-block;
    vertical-align: bottom;

    ${skeletonStyle}
`;
