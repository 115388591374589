import { RefObject, useEffect, useRef } from "react";

const defaultEvents = ["mousedown", "touchstart"];

export const useClickAway = <E extends Event = Event>(
    ref: RefObject<HTMLElement | null>,
    onClickAway: (event: E) => void,
    events: string[] = defaultEvents,
) => {
    const savedCallback = useRef(onClickAway);

    useEffect(() => {
        savedCallback.current = onClickAway;
    }, [onClickAway]);

    useEffect(() => {
        const handler = (event: E) => {
            const { current: el } = ref;
            const { target } = event;
            if (el && target && !el.contains(target as Node)) {
                savedCallback.current(event);
            }
        };

        for (const eventName of events) {
            document.addEventListener(eventName, handler);
        }
        return () => {
            for (const eventName of events) {
                document.removeEventListener(eventName, handler);
            }
        };
    }, [events, ref]);
};

export default useClickAway;
