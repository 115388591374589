import styled from "styled-components";

type Props = {
    noGutters?: boolean;
};

const Grid = styled.div<Props>`
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${({ theme, noGutters }) => (noGutters ? 0 : theme.spacing[5])};
    grid-auto-flow: row;
    grid-auto-rows: max-content;
`;

export default Grid;
