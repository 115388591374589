/* eslint-disable react/display-name */
import { useObjectRef } from "@react-aria/utils";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { AriaCheckboxProps, useCheckbox, useFocusRing } from "react-aria";
import { useToggleState } from "react-stately";
import styled, { css } from "styled-components";

import { SvgIcon, Check, Minus } from "@sol/icons";

import { focusRingCSS } from "../utils/focus";

type BoxProps = {
    isSelected?: boolean;
    isFocusVisible?: boolean;
};

const Box = styled.span<BoxProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
    border: 2px solid var(--checkbox-border-color);
    box-shadow: var(--focus-ring-shadow);
    transition: all 0.2s;

    ${({ isSelected }) =>
        isSelected &&
        css`
            background: var(--checkbox-color);
        `}
    ${({ isFocusVisible }) =>
        css`
            --focus-ring-active: ${isFocusVisible ? 1 : 0};
        `}
    
    ${focusRingCSS};
`;

const Input = styled.input`
    height: 18px;
    width: 18px;
    margin: 0px;

    position: absolute;
    opacity: 0.001;
    --checkbox-cursor-hit-x: 0px;
    inset-inline-start: calc(var(--spectrum-checkbox-cursor-hit-x) * -1);
    inline-size: calc(100% + var(--spectrum-checkbox-cursor-hit-x));

    cursor: pointer;
`;

type Props = { className?: string } & AriaCheckboxProps;

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, Props> = ({ className, ...props }, forwardedRef) => {
    const inputRef = useObjectRef(forwardedRef);

    const { children, isIndeterminate } = props;
    const state = useToggleState(props);
    const { inputProps } = useCheckbox(props, state, inputRef);
    const { focusProps, isFocusVisible } = useFocusRing();
    const { isSelected } = state;

    return (
        <label className={className}>
            <Input {...inputProps} {...focusProps} ref={inputRef} />

            <Box isSelected={isSelected} isFocusVisible={isFocusVisible}>
                {isSelected && (isIndeterminate ? <Minus size={12} /> : <Check size={12} />)}
            </Box>
            {children}
        </label>
    );
};

export default styled(forwardRef(Checkbox))`
    --checkbox-color: ${({ theme }) => theme.palette.purple.base};
    --checkbox-focus-color: ${({ theme }) => theme.palette.purple.light};
    --checkbox-border-color: ${({ theme }) => theme.palette.black.base};

    --icon-color: ${({ theme }) => theme.palette.white.base};

    ${({ theme }) => theme.typography.p}

    display: inline-flex;
    align-items: center;

    outline: none;
    border: 0;
    transition: all 0.2s;
    justify-content: center;
    text-align: left;
    gap: ${({ theme }) => theme.spacing[4]};

    ${({ isDisabled }) =>
        isDisabled
            ? css`
                  opacity: 0.5;
              `
            : css`
                  cursor: pointer;
                  &:active > ${Box} {
                      box-shadow: var(--focus-ring-shadow), inset 0px 0px 0px 2px var(--checkbox-color);
                  }

                  &:hover {
                      > ${Box} {
                          --checkbox-border-color: var(--checkbox-color);
                          background: ${({ theme }) => theme.palette.white.base};

                          > ${SvgIcon} {
                              --icon-color: var(--checkbox-color);
                          }
                      }
                  }
              `}
`;
