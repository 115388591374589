import React, { FC, ReactNode, RefObject, useRef } from "react";
import { AriaButtonOptions, useButton, useFocusRing } from "react-aria";

import { Loader } from "@sol/uikit/core/icons";

import { basicButtonsVariants, loaderVariants, type BasicButtonsVariantsProps } from "./variants";

export interface ButtonProps
    extends AriaButtonOptions<"button">,
        Omit<BasicButtonsVariantsProps, "variant">,
        Required<Pick<BasicButtonsVariantsProps, "variant">> {
    forwardRef?: RefObject<HTMLButtonElement>;
    isLoading?: boolean;
    disabled?: boolean;
    className?: string;
    children: ReactNode;
}

export const Button: FC<ButtonProps> = ({ forwardRef, variant, size, isLoading, disabled = false, ...props }) => {
    const { children, className = "" } = props;

    const buttonRef = forwardRef ?? useRef(null);
    const { buttonProps } = useButton(props, buttonRef);
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
        <button
            {...buttonProps}
            {...focusProps}
            ref={buttonRef}
            className={basicButtonsVariants({
                variant,
                size,
                className: `${className} ${isFocusVisible ? "focus-classes" : ""}`,
            })}
            disabled={disabled || isLoading}
        >
            {isLoading ? (
                <Loader
                    size={size === "small" ? 24 : 32}
                    fill={`${size && variant ? loaderVariants[size][variant].fillColor : "white-base"}`}
                    stroke={`${size && variant ? loaderVariants[size][variant].strokeColor : "white-base"}`}
                />
            ) : (
                children
            )}
        </button>
    );
};
